.min-height-500 {
  min-height: 500px;
}

.min-height-sm {
  min-height: 300px;
}

.min-height-lg {
  min-height: 650px;
}

.mt-lg {
  margin-top: 1.7rem !important;
}

.paddingbottom-100 {
  padding-bottom: 100px !important;
}

p {
  font-weight: 400 !important;
}

.media,
.media-post {
  word-break: normal !important;
}

.cursor {
  cursor: pointer;
}

.how-it-works .card[data-background] {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.how-it-works h2 {
  color: #f5593d;
}

.how-it-works .card[data-background="image"]:after {
  background-color: rgba(255, 255, 255, 0);
}

.change-school {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.mlb-alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1 !important;
}

.mlb-alert {
  padding: 13px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.mlb-alert-primary {
  color: #fff;
  background-color: #ffc0a4;
  border-color: #ffc0a4 !important;
}

.modal-myaccount-header-animation {
  animation-duration: 3s;
  animation-delay: 2s;
  animation-iteration-count: 3;
}

.mlb-divider {
  background-color: #dddddd;
  display: inline-block;
  /*float: inherit;*/
  height: 63px;
  margin: 0px -3px;
  width: 1px;
}

.bg-color-danger {
  background-color: #f5593d !important;
}
.fw-strong {
  font-weight: 400;
}

.fs-42 {
  font-size: 42px;
}

.fa-cc-amex {
  color: #007bc1;
}

.cc-diners-club {
  color: #004a97;
}
.fa-cc-discover {
  color: #f68121;
}
.fa-cc-jcb {
  color: #003a8f;
}
.fa-cc-mastercard {
  color: #0a3a82;
}
.fa-cc-visa {
  color: #0157a2;
}

.color-white {
  color: #ffffff !important;
}

.br-solid {
  border-right: 1px solid #f2f2f2;
}

.bl-solid {
  border-left: 1px solid #f2f2f2;
}

.mobile-cart-footer .notification-bubble {
  padding: 0.4em 0.6em;
  position: absolute;
  top: 3px;
  right: -10px;
}

input,
.StripeElement {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  color: #66615b;
  font-size: 14px;
  transition: background-color 0.3s ease 0s;
  padding-right: 0 !important;
  padding: 7px 12px;
  height: 40px;
  box-shadow: none;
  font-family: "Montserrat", "Helvetica", Arial, sans-serif;
}

.StripeElement input::placeholder {
  background: #999 !important;
}

.table-header-font-strong {
  font-size: 1.15em !important;
  font-weight: 500 !important;
}

.insurance-card-link {
  text-transform: lowercase !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #51bcda !important;
}

.general-link {
  font-size: 14px !important;
  color: #51bcda !important;
}

.lb-notification-bubble {
  padding: 0.4em 0.6em !important;
  position: absolute !important;
  right: -5px !important;
}

.message-bar {
  width: 100%;
  height: 160px;
  background-color: #f5f5f5;
}

.message-bar .message-navigator {
  font-size: 24px;
}

.message-bar .message-body-area {
  font-size: 18px;
}

.message-bar .message-body-area-xs {
  font-size: 16px;
}

.message-bar .message-action-area {
  font-size: 16px;
}

.message-bar .message-navigator .color-default {
  color: #66615b;
}
