@media (max-width: 991px){
  .dropdown.show .dropdown-menu {
    display: block!important;
    position: static !important;
    float: none!important;
    background: transparent!important;
  }
  .navbar .navbar-nav .dropdown-menu .dropdown-menu{
    max-height: unset !important;
  }
  .dropdown .dropdown-menu .dropdown-toggle.dropdown-item {
    pointer-events: none;
  }
}

@media screen and (max-width: 991px) {
  .dropdown .dropdown-menu .dropdown-item:focus, .dropdown .dropdown-menu .dropdown-item:hover, .dropdown.show .dropdown-menu .dropdown-item:focus, .dropdown.show .dropdown-menu .dropdown-item:hover {
      color: #66615b;
  }
}